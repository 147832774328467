import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toastiy from "./Toastiy";
import { useAuth0 } from "@auth0/auth0-react";

const SessionExpired = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const logoutUser = () => {
    console.log("Logged out");
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  useEffect(() => {
    logoutUser();
    Toastiy("Session Expired. Login Required!");
    navigate("/login");
  }, []);
};

export default SessionExpired;
